/* eslint "no-undef": "off" */
export default {
  DEV: 'http://localhost:1337',
  STAGING: 'https://cubeco-staging-back.herokuapp.com',
  PRODUCTION: 'https://cubeco-prod-back.herokuapp.com'
}[process.env.REACT_APP_ENV || 'DEV'];

export const baseFrontUrl = {
  DEV: 'http://localhost:3001',
  STAGING: 'https://cubecoadmin-staging-front.herokuapp.com',
  PRODUCTION: 'https://admin.cubeco.thoody.com'
}[process.env.REACT_APP_ENV || 'DEV'];

export const hourOptions = [
  { label: '', value: null },
  { label: 'A', value: 0 },
  { label: 'P', value: 9 },
  { label: '4.5', value: 4.5 },
  { label: '0.5', value: 0.5 },
  { label: '1', value: 1 },
  { label: '1.5', value: 1.5 },
  { label: '2', value: 2 },
  { label: '2.5', value: 2.5 },
  { label: '3', value: 3 },
  { label: '3.5', value: 3.5 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '5.5', value: 5.5 },
  { label: '6', value: 6 },
  { label: '6.5', value: 6.5 },
  { label: '7', value: 7 },
  { label: '7.5', value: 7.5 },
  { label: '8', value: 8 },
  { label: '8.5', value: 8.5 }
];

export const styleColors = {
  'Boss Interim': {
    color: 'rgb(128, 0, 32)'
  },
  Cubeco: {
    color: 'rgb(255,140,0)'
  },
  MI: {
    color: 'rgb(255,0,0)'
  },
  ARC: {
    color: 'rgb(30,144,255)'
  },
  MCI: {
    color: 'rgb(0,0,0)'
  },
  FBI: {
    color: 'rgb(204,204,0)'
  },
  MGTT: {
    color: '#017500'
  },
  MBRI: {
    color: '#1e90ff'
  }
};
