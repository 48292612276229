import { useMutation, useQueryClient } from '@tanstack/react-query';

const usePersistMutation = (key, fnc, optimisticFnc) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: fnc,
    onMutate: async (variables) => {
      // Cancel current queries for the values list
      await queryClient.cancelQueries(key);

      // get the previous data
      const previousData = queryClient.getQueryData(key);

      queryClient.setQueriesData(key, (old) => optimisticFnc(old, variables));

      // return the previous data
      return { previousData };
    },
    onError: (error, variables, context) => {
      // Remove optimistic value from the values list
      if (context.previousData) {
        queryClient.setQueriesData(key, context.previousData);
      }
    },
    onSettled: () => queryClient.invalidateQueries(key)
  });
};

export default usePersistMutation;
