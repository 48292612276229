import React from 'react';
import moment from 'moment';
// import { InputTextarea } from '@components';
import { styleColors } from '@root/config';
import InputOnClick from '../../custom/InputOnClick/InputOnClick';

const generateHeader = (chantiers) => ['', '', ...chantiers.map((o) => o?.Nom)];

const EmployeeCell = ({ currentNbDays, employee }) => (
  <td
    rowSpan={currentNbDays || 5}
    width="20%"
    className={`text-center items-center bg-white min-w-[115px] sticky left-0 z-10 border border-b-2 border-l-2 border-l-black border-b-black`}>
    <strong
      className="thoody-outline-text"
      style={styleColors[employee.Agence]}>{`${employee.Prenom} ${employee.Nom}`}</strong>
  </td>
);

const DayCell = ({ currentNbDays, indexDay, days }) => {
  const lowBorderStyle = indexDay === (currentNbDays - 1 || 4) ? 'border-b-2 border-b-black' : '';

  return (
    <td
      width="2%"
      className={`text-center items-middle bg-white left-[115px] px-2 sticky z-10 border ${lowBorderStyle}`}>
      {days[indexDay]}
    </td>
  );
};

const MainTable = (props) => {
  const {
    employees = [],
    currentNbDays,
    currentSheet,
    chantiers = [],
    hours = [],
    days,
    updateValue
  } = props;

  const getCurrentHeure = (indexDay, employee, chantier) =>
    hours?.find(
      (o) =>
        o?.employee?.id === employee?.id &&
        o?.chantier?.id === chantier?.id &&
        o?.jour ===
          moment(currentSheet?.Debut, 'YYYY-MM-DD').add(indexDay, 'd').format('YYYY-MM-DD')
    );

  const lowBorderStyle = (indexDay) =>
    indexDay === (currentNbDays - 1 || 4) ? 'border-b-2 border-b-black' : '';

  const headers = generateHeader(chantiers);

  return (
    <div className="mx-2 overflow-auto" style={{ height: 'calc(100vh - 80px)' }}>
      <table
        className="table mb-0 border-separate border-spacing-0 border-r-2 border-r-black"
        width="100%">
        <thead className=" border-y-2 border-x-2 border-black">
          <tr className="text-center text-black">
            {headers.length > 0 &&
              headers.map((o, index) => (
                <th
                  className={`${
                    index === 0 ? 'bg-gray-100 border-l-2 border-l-black' : 'bg-white'
                  } ${index > 1 ? 'min-w-[115px]' : ''}  ${
                    index === headers.length ? 'border-r-2 border-r-black' : ''
                  } border-y-2 border-y-black border-x  sticky top-0 z-[11]`}
                  key={index}>
                  {o}
                </th>
              ))}
          </tr>
        </thead>
        <tbody className="text-black">
          {employees?.map((employee) =>
            Array(currentNbDays || 5)
              .fill()
              .map((_, indexDay) => (
                <tr key={indexDay}>
                  {/* Collapsing only the first row */}
                  {indexDay === 0 && (
                    <EmployeeCell currentNbDays={currentNbDays} employee={employee} />
                  )}
                  <DayCell currentNbDays={currentNbDays} days={days} indexDay={indexDay} />
                  {chantiers?.map((chantier, indexChantier) => (
                    <InputOnClick
                      key={indexChantier}
                      lowBorderStyle={lowBorderStyle}
                      value={getCurrentHeure(indexDay, employee, chantier)}
                      indexChantier={indexChantier}
                      indexDay={indexDay}
                      chantier={chantier}
                      fnc={(e, key) =>
                        updateValue(
                          indexDay,
                          chantier?.id,
                          employee?.id,
                          e.target.value,
                          getCurrentHeure(indexDay, employee, chantier)?.id,
                          key
                        )
                      }
                    />
                  ))}
                </tr>
              ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MainTable;
