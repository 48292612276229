import React, { useEffect } from 'react';
import { Notification } from '@components';

const MutationNotification = (props) => {
  const { show: showNotification = false, setShow: setShowNotification = () => {} } = props;

  useEffect(() => {
    if (showNotification) {
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
  }, [showNotification]);
  return (
    <Notification show={showNotification} setShow={setShowNotification} className={'z-40'}>
      L&apos;information a bien été sauvegardée.
    </Notification>
  );
};

export default MutationNotification;
