import { useState } from 'react';
import { Modal, DropDown } from '@components';
import baseUrl from '@root/config';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import moment from 'moment';

const MainHead = (props) => {
  const { currentSheet, sheets = [] } = props;

  const [showModal, setShowModal] = useState(false);
  const [showModalPDF, setShowModalPDF] = useState(false);
  const [idSheet, setIdSheet] = useState(null);

  return (
    <div className="w-full grid grid-cols-2 p-2 text-xl text-gray-800">
      <h2 className="mb-0 flex gap-2 self-center tracking-widest">
        <em>Relevé d&apos;heures / présence</em>
        <button
          className="rounded bg-gray-100 py-1 px-2 font-semibold text-gray-900 shadow-sm hover:bg-gray-300"
          type="button"
          onClick={() => setShowModalPDF(true)}>
          <ArrowDownTrayIcon className="w-5 h-5" />
        </button>
        <Modal isOpen={showModalPDF} setIsOpen={setShowModalPDF}>
          <div className="px-2 py-3 border-b">
            <h1 className="tracking-widest">Télécharger un rapport</h1>
          </div>

          <div className="flex justify-between p-3">
            <a
              href={`${baseUrl}/api/report/chantiers?s=${currentSheet?.id}&pdf=1`}
              className="transition p-2 rounded-md ring-1 ring-black hover:bg-black hover:text-white"
              target="_blank"
              rel="noreferrer">
              Rapport de chantiers
            </a>
            <a
              href={`${baseUrl}/api/report/salaries?s=${currentSheet?.id}&pdf=1`}
              target="_blank"
              className="transition ml-4 p-2 rounded-md ring-1 ring-black hover:bg-black hover:text-white"
              rel="noreferrer">
              Rapport des salariés
            </a>
          </div>
        </Modal>
      </h2>
      <div className="flex items-end flex-col">
        <button
          type="button"
          className="rounded bg-gray-100 py-1 px-2 text-gray-900 shadow-sm hover:bg-gray-300"
          onClick={() => setShowModal(true)}>
          <h2>{currentSheet?.Nom}</h2>
        </button>
        <small>
          <em className="text-sm text-gray-500">
            du {moment(currentSheet?.Debut, 'YYYY-MM-DD').format('DD.MM')} au{' '}
            {moment(currentSheet?.Fin, 'YYYY-MM-DD').format('DD.MM')}
          </em>
        </small>
        <Modal isOpen={showModal} setIsOpen={setShowModal}>
          <div className="px-3 py-2 border-b">
            <h1>Changer de semaine.</h1>
          </div>
          <div className="px-3 py-5 border-b">
            <DropDown
              label="Semaine :"
              selectName="Choisisser votre semaine."
              data={sheets?.map((o) => ({
                label: o.Nom,
                value: o.id
              }))}
              isControlled
              value={idSheet}
              onChange={(e) => setIdSheet(e.target.value)}
            />
          </div>
          <div className="px-3 py-2 text-right">
            <a
              id="LinkConditions"
              href={idSheet ? '/?s=' + idSheet : '/'}
              className="transition cursor-pointer hover:underline underline-offset-4">
              Changer
            </a>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default MainHead;
