import { useState } from 'react';

import { hourOptions } from '@root/config';
import { InputTextarea } from '@components';
const InputOnClick = (props) => {
  const {
    value = '',
    fnc = () => {},
    lowBorderStyle = {},
    indexChantier = 0,
    indexDay = 0,
    chantier = {}
  } = props;
  const [isShow, setIsShow] = useState(false);

  if (!isShow)
    return (
      <td
        key={indexChantier}
        onClick={() => setIsShow(true)}
        className={`p-4 border h-[58px] w-full cursor-pointer text-center ${lowBorderStyle(
          indexDay
        )}`}>
        {chantier?.Nom === 'AUTRE'
          ? value?.comment
          : {
              9: 'P',
              0: 'A'
            }[value?.heure] || value?.heure}
      </td>
    );
  else
    return (
      <td
        onMouseLeave={() => setTimeout(() => isShow && setIsShow(false), 2000)}
        className={`p-2 border h-full w-full  ${lowBorderStyle(indexDay)}`}>
        {chantier?.Nom === 'AUTRE' ? (
          <InputTextarea
            rows="1"
            defaultValue={value?.comment}
            onBlur={(e) => {
              fnc(e, 'comment');
              setIsShow(false);
            }}
          />
        ) : (
          <select
            value={value?.heure}
            onChange={(e) => {
              fnc(e);
              setIsShow(false);
            }}
            onBlur={() => setIsShow(false)}
            className="mt-1 w-full form-select appearance-none block grow px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-100 dark:bg-gray-800 bg-clip-padding bg-no-repeat border border-solid border-gray-300 dark:border-gray-500 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-gray-50 focus:border-teal-600 focus:outline-none">
            {hourOptions.map((item, idx) => (
              <option key={`option_${idx}`} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        )}
      </td>
    );
};

export default InputOnClick;
