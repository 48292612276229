import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import reportWebVitals from './reportWebVitals';
import { Main } from '@pages';
import './index.css';
import { baseFrontUrl } from '@root/config';

const persister = createSyncStoragePersister({
  storage: window.localStorage
});
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />
  },
  {
    path: '/admin',
    loader: () => redirect(baseFrontUrl)
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{ persister }}
    onSuccess={() => queryClient.resumePausedMutations()}>
    <RouterProvider router={router} />
    <ReactQueryDevtools initialIsOpen={false} />
  </PersistQueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
