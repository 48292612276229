/* eslint-disable */
import { useQuery, useMutation } from '@tanstack/react-query';
import moment from 'moment';
import queries from '@queries';
import _ from 'lodash';
import MainHead from './MainHead';
import { useSearchParams } from 'react-router-dom';
import MainTable from './MainTable';
import { usePersistMutation } from '@hooks';
import { NetworkNotification, MutationNotification } from '@custom';
import { useState } from 'react';

const days = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

const Main = () => {
  const [params] = useSearchParams();
  const [showNotification, setShowNotification] = useState(false);

  // Get id from current location
  const firstDay = moment().startOf('isoWeek').format('YYYY-MM-DD');
  const idGet = params.get('s');

  const { data: currentSheetDb, isLoading: currentSheetLoadingDb } = useQuery(
    ['sheets', 'current'],
    () =>
      queries
        .getAll(`/api/sheets`, {
          filters: {
            Debut: { $eq: firstDay }
          }
        })
        .then((res) => res?.data?.[0])
  );

  const { data: currentSheetId, isLoading: currentSheetLoadingId } = useQuery(
    ['sheets', idGet],
    () => queries.get(`/api/sheets`, idGet).then((res) => res?.data),
    { enabled: !!idGet }
  );

  const currentSheet = idGet
    ? !currentSheetLoadingId
      ? currentSheetId
      : null
    : !currentSheetLoadingDb
    ? currentSheetDb
    : null;

  // Fetch Data
  const { data: employees } = useQuery(
    ['employees'],
    () =>
      queries
        .getAll(`/api/employees`, {
          filters: {
            $and: [
              {
                $or: [{ Debut: { $lte: currentSheet?.Fin } }, { Debut: { $null: true } }]
              },
              {
                $or: [{ Fin: { $gte: currentSheet?.Debut } }, { Fin: { $null: true } }]
              }
            ]
          },
          sort: ['idReport:asc'],
          fields: ['Nom', 'Prenom', 'Agence', 'Debut', 'Fin']
        })
        .then((res) => res?.data),
    {
      enabled: !!currentSheet?.id
    }
  );

  const { data: chantiers, isLoading: chantiersLoading } = useQuery(
    ['chantiers'],
    () =>
      queries
        .getAll(`/api/chantiers`, {
          filters: {
            $and: [
              {
                $or: [{ Debut: { $lte: currentSheet?.Fin } }, { Debut: { $null: true } }]
              },
              {
                $or: [{ Fin: { $gte: currentSheet?.Debut } }, { Fin: { $null: true } }]
              }
            ]
          },
          sort: ['idReport:asc'],
          fields: ['Nom', 'Debut', 'Fin']
        })
        .then((res) => res?.data),
    {
      enabled: !!currentSheet?.id
    }
  );

  const { data: sheets, isFetching: sheetsFetching } = useQuery(['sheets'], () =>
    queries.getAll(`/api/sheets`, { fields: ['Nom', 'Debut'] }).then((res) => res?.data)
  );

  const { data: heures, isFetching: heuresFetching } = useQuery(
    ['heures'],
    () =>
      queries
        .getAll(`/api/heures`, {
          filters: {
            feuille: currentSheet?.id
          },
          populate: ['chantier', 'employee']
        })
        .then((res) => res?.data),
    { enabled: !!currentSheet?.id }
  );

  // Update Data

  const createHeuresMutation = usePersistMutation(
    ['heures'],
    (heures) => queries.post(`/api/heures`, heures),
    (old, values) => [
      ...old,
      ...values.map((o) => ({ ...o, employee: { id: o?.employee }, chantier: { id: o?.chantier } }))
    ]
  );

  const updateHeuresMutation = usePersistMutation(
    ['heures'],
    (heures) => queries.put(`/api/heures`, heures),
    (old, values) => {
      const temp = JSON.parse(JSON.stringify(old));
      const heureIndex = old?.findIndex((e) => e?.id === values?.at(0)?.id);

      temp[heureIndex] = {
        ...temp[heureIndex],
        heure: values?.at(0).heure
      };
      return temp;
    }
  );

  if (
    !sheetsFetching &&
    !heuresFetching &&
    (createHeuresMutation?.isSuccess || updateHeuresMutation?.isSuccess)
  ) {
    setShowNotification(true);
    [updateHeuresMutation, createHeuresMutation]
      .filter((m) => !m?.isLoading)
      .map((m) => m.reset());
  }

  const updateValue = async (indexDay, idChantier, idEmployee, value, id, type = 'value') => {
    const day = moment(currentSheet?.Debut, 'YYYY-MM-DD').add(indexDay, 'd').format('YYYY-MM-DD');

    const payload = {
      id,
      jour: day,
      employee: idEmployee,
      chantier: idChantier,
      feuille: currentSheet?.id,
      ...(type === 'value' && { heure: parseFloat(value) }),
      ...(type === 'comment' && { comment: value })
    };

    if (id) updateHeuresMutation.mutate([payload]);
    else createHeuresMutation.mutate([payload]);
  };

  // Compute data
  const currentNbDays =
    moment(currentSheet?.Fin).diff(moment(currentSheet?.Debut), 'days') + 1 || 5;

  // Sort Data
  const sortedSheets = sheets
    ?.sort((curr, prev) => moment(curr?.Debut).diff(prev?.Debut))
    .reverse();

  return (
    <div>
      <NetworkNotification />
      <MutationNotification show={showNotification} setShow={setShowNotification} />
      <MainHead currentSheet={currentSheet} sheets={sortedSheets} />
      <MainTable
        employees={employees}
        currentNbDays={currentNbDays}
        currentSheet={currentSheet}
        chantiers={chantiers}
        days={days}
        hours={heures}
        updateValue={updateValue}
      />
    </div>
  );
};

export default Main;
